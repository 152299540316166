import { combineReducers } from "redux";

import slice from "./costSlice";
import costOverview from "./costOverview";

const reducer = combineReducers({
    root: slice.reducer,
    costOverview
});

export default reducer;
