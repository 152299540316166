import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import {
    selectIsLoading,
    selectHasErrors,
    selectLatestMonthAndPriorYearTotalCosts
} from "modules/customer/insights/cost/costOverview/costOverviewSlice";

import KPIComparison from "components/visuals/KPIComparison";
import PercentageIndicator from "components/visuals/PercentageIndicator";
import numberFormatter from "utils/numberFormatter";
import mathUtils from "utils/mathUtils";

const LatestMonthTotalCosts = () => {
    const isLoading = useAppSelector(selectIsLoading);
    const hasErrors = useAppSelector(selectHasErrors);
    const latestMonthAndPriorYearTotalCosts = useAppSelector(selectLatestMonthAndPriorYearTotalCosts);

    const label = `Total costs in ${latestMonthAndPriorYearTotalCosts.data.latestMonthDate.monthLong} ${latestMonthAndPriorYearTotalCosts.data.latestMonthDate.year}`;
    const difference = mathUtils.safePercentageChange(latestMonthAndPriorYearTotalCosts.data.latestMonthTotalCosts, latestMonthAndPriorYearTotalCosts.data.yearPriorTotalCosts);

    return (
        <Box width="100%" height="100%" data-cy="latest-month-vs-previous-year-total-costs">
            <KPIComparison
                isLoading={isLoading}
                hasErrors={hasErrors}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(latestMonthAndPriorYearTotalCosts.data.latestMonthTotalCosts, 1)}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <PercentageIndicator loading={false} error={false} value={difference} switchColor={true} noDataMessage={""} />
                }
                comparisonLabel={"% change from previous year"}
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(latestMonthAndPriorYearTotalCosts.data.yearPriorTotalCosts, 1)}
                    </Typography>
                }
                differenceLabel={`${latestMonthAndPriorYearTotalCosts.data.yearPriorDate.monthLong} ${latestMonthAndPriorYearTotalCosts.data.yearPriorDate.year}`}
            />
        </Box>
    );
};

export default LatestMonthTotalCosts;
