import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

import { clearCostOverview, loadCostOverview } from "./costOverview/costOverviewSlice";

export enum CostChapter {
    CostOverview = 1,
    CostDrivers,
    StoreCosts
}

interface LoadCostResponse { }

interface CostState {
    isLoading: boolean,
    hasErrors: boolean,
    currentChapter: CostChapter
}

const initialState: CostState = {
    isLoading: false,
    hasErrors: false,
    currentChapter: CostChapter.CostOverview
};

const costSlice = createSlice({
    name: "customer/inisghts/cost",
    initialState,
    reducers: {
        setCurrentChapter: (state, action: PayloadAction<CostChapter>) => {
            state.currentChapter = action.payload;
        },
        resetCurrentChapter: (state) => {
            state.currentChapter = initialState.currentChapter;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCost.pending, (state: CostState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadCost.rejected, (state: CostState) => {
            state.isLoading = false;
            state.hasErrors = true;
        });
        builder.addCase(loadCost.fulfilled, (state: CostState, action: PayloadAction<LoadCostResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
        });
    }
});

export const {
    setCurrentChapter
} = costSlice.actions;

export const loadCost = createAppAsyncThunk(
    "customer/insights/cost/loadCost",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());
            const loadCostResponse: LoadCostResponse = {

            };
            return loadCostResponse;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading Cost."));
            return thunkAPI.rejectWithValue(null);
        }
        finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearCost = (): AppThunk => async (dispatch) => {
    dispatch(costSlice.actions.resetCurrentChapter());
    dispatch(clearInsights());
};

export const loadInsights = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(loadCostOverview());
    }
    catch (error) {
        dispatch(logError("Error loading Insights.", error));
    }
};

export const clearInsights = (): AppThunk => (dispatch) => {
    dispatch(clearCostOverview());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.root.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.root.hasErrors;
};

export const selectCurrentChapter = (state: RootState) => {
    return state.customer.insights.cost.root.currentChapter;
};

export default costSlice;
