import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DateTime } from "luxon";
import { DataWrapper } from "domain/dataWrapper";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";

import { loadCostReferenceDate } from "./costReferenceDate";
import { MonthlyCosts, loadMonthlyCosts } from "./monthlyCosts";
import { MonthlyRevenue, loadMonthlyRevenue } from "./monthlyRevenue";

interface CostOverviewState {
    isLoading: boolean,
    hasErrors: boolean,
    costReferenceDate: DateTime,
    monthlyCosts: MonthlyCosts[],
    monthlyRevenue: MonthlyRevenue[]
}

const initialState: CostOverviewState = {
    isLoading: false,
    hasErrors: false,
    costReferenceDate: DateTime.fromMillis(0, { zone: "utc" }),
    monthlyCosts: [],
    monthlyRevenue: []
};

interface LoadCostOverviewResponse {
    costsReferenceDate: DateTime,
    monthlyCosts: MonthlyCosts[],
    monthlyRevenue: MonthlyRevenue[]
}

const costOverviewSlice = createSlice({
    name: "customer/inisghts/cost/costOverview",
    initialState,
    reducers: {
        clearCostsReferenceDate: (state) => {
            state.costReferenceDate = initialState.costReferenceDate;
        },
        clearMonthlyCosts: (state) => {
            state.monthlyCosts = initialState.monthlyCosts;
        },
        clearMonthlyRevenue: (state) => {
            state.monthlyRevenue = initialState.monthlyRevenue;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCostOverview.pending, (state: CostOverviewState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.costReferenceDate = initialState.costReferenceDate;
            state.monthlyCosts = initialState.monthlyCosts;
            state.monthlyRevenue = initialState.monthlyRevenue;
        });
        builder.addCase(loadCostOverview.rejected, (state: CostOverviewState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.costReferenceDate = initialState.costReferenceDate;
            state.monthlyCosts = initialState.monthlyCosts;
            state.monthlyRevenue = initialState.monthlyRevenue;
        });
        builder.addCase(loadCostOverview.fulfilled, (state: CostOverviewState, action: PayloadAction<LoadCostOverviewResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.costReferenceDate = action.payload.costsReferenceDate;
            state.monthlyCosts = action.payload.monthlyCosts;
            state.monthlyRevenue = action.payload.monthlyRevenue;
        });
    }
});

export const loadCostOverview = createAppAsyncThunk(
    "customer/insights/cost/costOverview/loadCostOverview",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());

            const costsReferenceDatePromise = thunkAPI.dispatch(loadCostReferenceDate());
            const setupResults = await Promise.all([
                costsReferenceDatePromise
            ]);
            const costsReferenceDate = setupResults[0];

            const monthlyCostsPromise = thunkAPI.dispatch(loadMonthlyCosts(costsReferenceDate));
            const monthlyRevenuePromise = thunkAPI.dispatch(loadMonthlyRevenue(costsReferenceDate));
            const results = await Promise.all([
                monthlyCostsPromise,
                monthlyRevenuePromise
            ]);
            const monthlyCosts = results[0];
            const monthlyRevenue = results[1];

            const loadCostResponse: LoadCostOverviewResponse = {
                costsReferenceDate,
                monthlyCosts,
                monthlyRevenue
            };
            return loadCostResponse;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading CostOverview."));
            return thunkAPI.rejectWithValue(null);
        }
        finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearCostOverview = (): AppThunk => (dispatch) => {
    dispatch(costOverviewSlice.actions.clearCostsReferenceDate());
    dispatch(costOverviewSlice.actions.clearMonthlyCosts());
    dispatch(costOverviewSlice.actions.clearMonthlyRevenue());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.costOverview.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.costOverview.hasErrors;
};

export const selectCostsReferenceDate = (state: RootState) => {
    return state.customer.insights.cost.costOverview.costReferenceDate;
};

export const selectMonthlyCosts = (state: RootState) => {
    return state.customer.insights.cost.costOverview.monthlyCosts;
};

export const selectMonthlyRevenue = (state: RootState) => {
    return state.customer.insights.cost.costOverview.monthlyRevenue;
};

export const selectPastTwelveMonthsCosts = createSelector(
    (state: RootState) => selectCostsReferenceDate(state),
    (state: RootState) => selectMonthlyCosts(state),
    (costReferenceDate, monthlyCosts) => {
        if (!monthlyCosts) {
            return 0;
        }

        const twelveMonthsPriorReferenceDate = costReferenceDate.minus({ months: 12 });

        return monthlyCosts.filter(costs => costs.date > twelveMonthsPriorReferenceDate)
            .reduce((total, item) => item.costValue + total, 0);
    }
);

export const selectPreviousYearCosts = createSelector(
    (state: RootState) => selectCostsReferenceDate(state),
    (state: RootState) => selectMonthlyCosts(state),
    (costReferenceDate, monthlyCosts) => {
        if (!monthlyCosts) {
            return 0;
        }

        const twelveMonthsPriorReferenceDate = costReferenceDate.minus({ months: 12 });
        const twentyFourMonthsPriorReferenceDate = costReferenceDate.minus({ months: 24 });

        return monthlyCosts.filter(costs => (costs.date > twentyFourMonthsPriorReferenceDate)
            && (costs.date <= twelveMonthsPriorReferenceDate))
            .reduce((total, item) => item.costValue + total, 0);
    }
);

export const selectLatestMonthAndPriorYearTotalCosts = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectCostsReferenceDate(state),
    (state: RootState) => selectMonthlyCosts(state),
    (isLoading, hasErrors, costsReferenceDate, monthlyCosts) => {
        interface LatestMonthAndPriorYearTotalCosts {
            latestMonthDate: DateTime
            latestMonthTotalCosts: number
            yearPriorDate: DateTime
            yearPriorTotalCosts: number
        }

        const latestMonthAndPriorYearTotalCosts: DataWrapper<LatestMonthAndPriorYearTotalCosts> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: {
                latestMonthDate: DateTime.fromMillis(0, { zone: "utc" }),
                latestMonthTotalCosts: 0,
                yearPriorDate: DateTime.fromMillis(0, { zone: "utc" }),
                yearPriorTotalCosts: 0
            }
        };

        if (!costsReferenceDate || !monthlyCosts || latestMonthAndPriorYearTotalCosts.hasErrors || latestMonthAndPriorYearTotalCosts.isLoading) {
            return latestMonthAndPriorYearTotalCosts;
        }

        const latestMonth = costsReferenceDate.startOf("month");
        const latestMonthTotalCosts = monthlyCosts.filter(costs => costs.date.equals(latestMonth))
            .reduce((total, item) => item.costValue + total, 0);

        const priorYearDate = costsReferenceDate.minus({ months: 12 }).startOf("month");
        const priorYearTotalCosts = monthlyCosts.filter(costs => costs.date.equals(priorYearDate))
            .reduce((total, item) => item.costValue + total, 0);

        latestMonthAndPriorYearTotalCosts.data.latestMonthDate = latestMonth;
        latestMonthAndPriorYearTotalCosts.data.latestMonthTotalCosts = latestMonthTotalCosts;
        latestMonthAndPriorYearTotalCosts.data.yearPriorDate = priorYearDate;
        latestMonthAndPriorYearTotalCosts.data.yearPriorTotalCosts = priorYearTotalCosts;

        return latestMonthAndPriorYearTotalCosts;
    }
);

export const selectPastTwelveMonthsPercentageOfRevenue = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectCostsReferenceDate(state),
    (state: RootState) => selectMonthlyRevenue(state),
    (state: RootState) => selectPastTwelveMonthsCosts(state),
    (state: RootState) => selectPreviousYearCosts(state),
    (isLoading, hasErrors, costReferenceDate, monthlyRevenue, pastTwelveMonthsTotalCosts, previousYearTotalCosts) => {
        interface PastTwelveMonthsPercentageOfRevenue {
            pastTwelveMonthsPercentage: number
            previousYearPercentage: number
            difference: number
        }

        const pastTwelveMonthsPercentageOfRevenue: DataWrapper<PastTwelveMonthsPercentageOfRevenue> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: {
                pastTwelveMonthsPercentage: 0,
                previousYearPercentage: 0,
                difference: 0
            }
        };

        if (!monthlyRevenue ||
            !pastTwelveMonthsTotalCosts ||
            !previousYearTotalCosts ||
            pastTwelveMonthsPercentageOfRevenue.hasErrors ||
            pastTwelveMonthsPercentageOfRevenue.isLoading) {
            return pastTwelveMonthsPercentageOfRevenue;
        }

        const twelveMonthsPriorReferenceDate = costReferenceDate.minus({ months: 12 }).startOf("month");
        const pastTwelveMonthsRevenue = monthlyRevenue.filter(revenue => revenue.date > twelveMonthsPriorReferenceDate)
            .reduce((total, item) => item.revenue + total, 0);

        const twentyFourMonthsPriorReferenceDate = costReferenceDate.minus({ months: 24 }).startOf("month");
        const yearPriorRevenue = monthlyRevenue.filter(revenue => (revenue.date <= twelveMonthsPriorReferenceDate)
            && (revenue.date > twentyFourMonthsPriorReferenceDate))
            .reduce((total, item) => item.revenue + total, 0);

        pastTwelveMonthsPercentageOfRevenue.data.pastTwelveMonthsPercentage = mathUtils.safePercentage(pastTwelveMonthsTotalCosts, pastTwelveMonthsRevenue);
        pastTwelveMonthsPercentageOfRevenue.data.previousYearPercentage = mathUtils.safePercentage(previousYearTotalCosts, yearPriorRevenue);
        pastTwelveMonthsPercentageOfRevenue.data.difference = pastTwelveMonthsPercentageOfRevenue.data.pastTwelveMonthsPercentage - pastTwelveMonthsPercentageOfRevenue.data.previousYearPercentage;

        return pastTwelveMonthsPercentageOfRevenue;
    }
);

export default costOverviewSlice;
