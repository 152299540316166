import React from "react";

import { Grid } from "@material-ui/core";

import PastTwelveMonthsTotalCosts from "./PastTwelveMonthsTotalCosts";
import LatestMonthTotalCosts from "./LatestMonthTotalCosts";
import PercentageOfRevenue from "./PercentageOfRevenue";

const CostOverview: React.FC = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <PastTwelveMonthsTotalCosts />
            </Grid>
            <Grid item xs={12} md={4}>
                <LatestMonthTotalCosts />
            </Grid>
            <Grid item xs={12} md={4}>
                <PercentageOfRevenue />
            </Grid>
        </Grid>
    );
};

export default CostOverview;
